.services-service-desc {
    text-align: center;
    width: 70%;
    margin: auto;
}

.services-svc-container {
    width: 70%;
    margin: auto;
    text-align: center;
}

.services-svc {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(2, 3fr);
}

.services-svc a {
    text-decoration: none;
    color: black;
}

.services-svc a:hover div{
    background-position: 0 100%;
    color: white;
}

.services-svc div {
    margin: 10px;
    padding: 20px;
    text-align: center;
    min-height: 370px;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, #f5f5f5 50%, green 50%);
    transition: background-position 0.3s;
}

.service-industries-container {
    display: flex;
    margin: 20px;
}

.service-industries-item {
    text-align: center;
    margin: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f5f5f5;
}

.service-industries-item img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.service-industries-item-title {
    min-height: 250px;
}

.service-industries-item-title p {
    padding: 0px 20px;
}

.service-other-container {
    display: flex;
    width: 70%;
    margin: 0px auto 30px;
    justify-content: center;
}

.service-other {
    text-align: center;
}

.service-other-text {
    text-align: left;
}

@media (max-width: 600px) {
    .services-svc-container {
        width: 90%;
    }

    .services-svc {
        margin: auto;
        grid-template-columns: repeat(1, 3fr);
        grid-template-rows: repeat(6, 3fr);
    }

    .service-industries-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 3fr);
        margin: 20px;
    }

    .service-industries-item {
        margin: 10px 0px;
    }
}