html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.contact-container {
    text-align: center;
}

.contact-container label {
    font-size: large;
}

.contact-area-container {
    margin: 40px 0px;
    padding: 0 40px;
}

.contact-form {
    display: flex;
    width: 80%;
    margin: auto;
    padding: 20px;
    background-color: #f5f5f5;
}

.contact-form-box {
    width: 50%;
}

form {
    margin: 10px;
}

.contact-form-title {
    text-align: left;
    margin: 10px;
}

.contact-input-box {
    display: flex;
    margin: 10px 0px;
}

.contact-input-box div {
    text-align: left;
    width: 100%;
    margin: 0px 10px;
}

.contact-input-box input {
    padding: 10px;
    margin: 10px 0px;
    width: 100%;
    height: 35px;
    border: 1px solid black;
    font-family:"Poppins", sans-serif;
    font-size: medium;
}

.contact-message textarea {
    padding: 10px;
    margin: 10px 0px;
}

#message {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 200px;
    border: 1px solid black;
    font-family:"Poppins", sans-serif;
    font-size: medium;
}

.contact-submit {
    text-align: left;
    margin: 5px 10px;
}

.contact-info-box {
    text-align: left;
    width: 50%;
}

.contact-info-title {
    text-align: left;
    margin: 10px;
    padding-left: 30px;
}

.contact-info-details-list {
    list-style: none;
}

.contact-map{
    width: 100%;
    height: 350px;
    padding: 0px 40px;
}

iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 600px) {
    .contact-area-container {
        padding: 0px;
    }

    .contact-form {
        width: 90%;
        margin: 0px auto;
        display: block;
    }

    .contact-form-box {
        width: 100%;
    }

    .contact-form-title {
        margin: 10px 0px;
    }

    .contact-input-box {
        display: block;
    }

    .contact-input-box > div {
        margin: 0px;
    }

    .contact-submit {
        text-align: center;
    }

    .contact-info-box {
        margin: 20px auto;
        width: 90%;
    }

    .contact-info-title {
        padding: 0px;
    }

    .contact-info-details-list {
        padding: 0px;
    }

    .contact-map {
        padding: 0px;
    }
}
