.personal-container {
    text-align: center;
    margin: auto;
}

.personal-desc-container {
    text-align: left;
    width: 70%;
    margin: auto;
}

.personal-desc-checklist {
    display: flex;
    justify-content: center;
}

.checklist-item {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.checklist-item p{
    margin: 0px;
}

.personal-pricing-container {
    text-align: center;
}

.personal-pricing-item-container {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.personal-pricing-item {
    max-width: 400px;
    width: 100%;
    margin: 20px;
    padding: 20px;
    background-color: #f5f5f5;
}

.personal-contact-btn {
    margin: 0px 0px 30px;
}

@media (max-width: 600px) {
    .personal-desc-container {
        width: 90%;
        margin: auto;
    }

    .personal-desc-checklist {
        display: inline-block;
    }

    .personal-pricing-item-container {
        display: inline-block;
    }

    .personal-pricing-item {
        margin: 20px 0px ;
    }
}