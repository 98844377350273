.about-information-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.about-information {
    display: flex;
    justify-content: center;
    padding: 0px 40px;
    margin: 0px 0px 30px;
    background-color: #f5f5f5;
}

.about-information-title {
    margin: 20px;
    text-align: left;
}

.about-information-title ol {
    display: flex;
    justify-content: center;
}

.about-information-title-list {
    margin: 0px 50px;
}

.about-information-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.about-information-buttons button {
    margin: 0px 20px;
}

.about-information-image {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-information-image img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 200px;
}

@media (max-width: 1200px) {
    .about-information {
        width: 100%;
        display: block;
        padding: 30px;
    }

    .about-information-image {
        display: none;
    }

    .about-information-image img {
        display: none;
    }
}

@media (max-width: 600px) {
    .about-information-container {
        width: 90%;
    }

    .about-information {
        width: 100%;
        display: block;
        padding: 30px;
    }

    .about-information-title {
        width: 100%;
        margin: auto;
    }

    .about-information-title h4 {
        margin: 0px;
    }

    .about-information-title ol {
        display: block;
    }

    .about-information-buttons {
        display: block;
        text-align: center;
        margin: 10px 0px;
    }

    .about-information-buttons button {
        margin: 10px 10px;
    }

    .about-information-image {
        display: none;
    }

    .about-information-image img {
        display: none;
    }
}