.news-container {
    text-align: center;
    width: 100%;
    margin: auto;
}

.news-container > p {
    width: 70%;
    margin: auto;
}

.news-items-container {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(auto-fill,3fr);
    justify-content: center;
    align-items: center;
    margin: auto;
}

.news-item {
    width: 400px;
    margin: 10px;
    background-color: #f5f5f5;
}

.news-item-content {
    height: 300px;
    margin: 20px;
    text-align: left;
}

.news-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.news-item strong {
    font-size: 18px;
    font-weight: 500;
}

.news-item-content a {
    text-decoration: none;
}

.news-contact-btn {
    text-align: center;
    margin: 30px;
}

.contact-submit-button-disable {
    background-color: #b6b6b6;
}

.contact-submit-button-disable:hover {
    background-color: #b6b6b6;
    cursor: default;
}

@media (max-width: 600px) {
    .news-container > p {
        width: 90%;
        margin: auto;
    }

    .news-items-container {
        width: 90%;
        grid-template-columns: repeat(1, 3fr);
        grid-template-rows: repeat(auto-fill,3fr);
    }
}