.home-title-banner {
    display: flex;
    min-height: 700px;
    justify-content: center;
    align-items: center;
  }
  
.home-title-img {
    width: 100%;
    height: 700px;
    object-fit:cover;
  }
  
.home-title {
    position: absolute;
    text-align: center;
}

.home-title h1 {
    color: white;
    margin: 0px;
}

.home-title p {
    width: 70%;
    margin: 30px auto;
    color: white;
    font-size: 30px;
}

.home-abt-container {
    width: 70%;
    min-height: 300px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-abt-container div {
    text-align: center;
}

.website-highlights {
    width: 90%;
    margin: 40px auto;
    text-align: center;
}

.website-highlights p {
    width: 60%;
    margin: auto;
}

.highlights-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlight {
    width: 100%;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
}

.highlight-row2:hover{
    transform: scale(1.02);
}
  
.highlight img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.highlight-inner {
    position: absolute;
    text-align: center;
    color: white;
}

@media (max-width: 600px) {
    .home-title h1 {
        font-size: 50px;
    }

    .home-title p {
        font-size: 25px;
    }

    .home-title-img {
        height: 700px;
    }

    .website-highlights p {
        width: 100%;
    }

    .highlight {
        margin: 10px 0px;
    }

    .highlight h1 {
        font-size: 15vw;
    }

    .highlights-row {
        width: 100%;
        margin: 0px;
    }

    .highlights-row-2 {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
    }
}