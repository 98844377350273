.resources-container {
    text-align: center;
    width: 70%;
    margin: auto;
}

.resources-links-container {
    margin: 10px 0px;
}

.resources-links-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: auto;
}

.resources-links-item-container a {
    text-decoration: none;
    color: black;
}

.resources-links-item-container a:hover div {
    background-position: 0 100%;
    color: white;
}

.resources-links-item-title {
    margin: 20px;
    padding: 20px;
    width: 370px;
    text-align: center;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, #f5f5f5 50%, green 50%);
    transition: background-position 0.3s;
}

@media (max-width: 600px) {
    .resources-container {
        width: 90%;
        margin: auto;
    }

    .resources-links-container {
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .resources-links-item-container {
        display: block;
        width: 100%;
        margin: 0px;
    }

    .resources-links-item-title {
        margin: 20px auto;
        width: 100%;
    }
}