@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 70px;
  margin: 20px;
}

h2 {
  font-size: 50px;
  margin: 20px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

button {
  background-color: green;
  color: white;
  font-size: 20px;
  padding: 7px 20px;
  border: none;
  cursor: pointer;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  font-family: 'Poppins', sans-serif;
  }
  
button:hover {
  background-color: #44cd3f;
}

/* Navbar */

nav {
  height: 63px;
  background-color: green;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin: auto;
}

nav .logo > a {
  text-decoration: none;
  color: white;
}

.logo h2 {
  margin: 0px;
  font-size: 40px;
}

nav .links {
  display: flex;
  align-items: center;
  max-height: 63px;
}

.links a:hover{
  background-color: #44cd3f;
}

.links > a{
  text-decoration: none;
  color: white;
  padding: 0px;
}

.links p {
  margin: 19px 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 150px;
  z-index: 10;
  font-size: 15px;
}

.dropdown-content a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.dropdown-content p {
  margin: 0px;
  padding: 7px 20px;
}

.nav-drop:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover p {
  background-color: green;
  color: white;
}

.mobile-container {
  display: none;
  color: white;
}

.mobile-links-hidden {
  display: none;
}

/* Header */

.pages-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
}

.pages-header-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pages-header-titles {
  position: absolute;
  text-align: center;
}

.pages-header-titles h1 {
  margin: 10px;
  color: white;
}
.pages-header-titles h4 {
  font-weight: 400;
  margin: 10px;
  color: white;
}

/* Page Break*/

.page-break {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: fit-content;
  background-color: green;
}

.page-break p {
  color: white;
  font-size: 30px;
  margin: 10px;
}

/* Footer */

.footer-container {
  height: fit-content;
  background-color: rgb(46, 46, 46);
  text-align: center;
  color: #e5e5e5;
}

.footer-content {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.footer-about {
  text-align: left;
  width: 40%;
  margin: 20px;
}

.footer-content-item {
  text-align: left;
  margin: 20px;
}

.footer-content-item ul{
  padding: 0px;
  text-align: left;
}

.footer-content-item > p {
  text-decoration: underline;
}

.footer-container li {
  margin: 5px 0px;
  list-style: none;
}

.footer-container a {
  text-decoration: none;
  color: #e5e5e5;
}

.footer-container a:hover {
  color: #ffffff;
}

.copyright{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 70%;
  border-top: 1px solid #e5e5e5;
}

@media (max-width: 600px) {

  /* Page Break*/
  .page-break-text-container {
    text-align: center;
  }

  .page-break p {
    color: white;
    font-size: 25px;
  }

  /* Footer */

  .footer-content {
    display:inline-block;
    text-align: center;
  }

  .footer-about, .footer-services, .footer-blog{
    display: none;
  }

  .footer-content-item {
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .footer-content-item ul{
    text-align: center;
    width: 100%;
    margin: auto;
  }

  .footer-quick-links, .footer-contact-info {
    margin: 20px auto;
  }

  /* Navbar */

  nav .content {
    width: 100%;
    margin-left: 30px;
  }

  .mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.5px;
  }

  .mobile-links {
    position: absolute;
    width: 100%;
    background-color: rgba(105, 105, 105, 0.8);
    transform: translateY(193px);
    text-align: center;
  }

  .mobile-links a {
    text-decoration: none;
    color: white;
  }

  .mobile-links p:hover {
    background-color: rgb(157, 157, 157, 0.8);
  }

  .mobile-links p {
    padding: 20px 0px;
    margin: 0px;
  }

  nav .links {
    display: none;
  }
}