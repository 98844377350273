.tools-container {
    text-align: center;
    width: 70%;
    margin: auto;
}

.tools-templates-container {
    background-color: #f5f5f5;
    width: 50%;
    padding: 30px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tools-templates-item-container {
    width: 200px;
}

.tools-templates-items {
    list-style: none;
    width: 350px;
}

.tools-templates-items ul{
    list-style: none;
    text-align: left;
}

@media (max-width: 600px) {
    .tools-container {
        text-align: center;
        width: 90%;
        margin: auto;
    }

    .tools-templates-container {
        width: 100%;
        display: block;
    }

    .tools-templates-item-container {
        margin: auto;
    }

    .tools-templates-items {
        width: 100%;
    }

    .tools-templates-items ul {
        padding: 0px;
        text-align: center;
    }
}
