.FAQ-container {
    text-align: center;
    width: 70%;
    margin: auto;
}

.FAQ-questions-item {
    margin: 20px;
}

.FAQ-questions-item-title {
    padding: 10px 40px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.FAQ-questions-item-answer {
    background-color: #f5f5f5;
    padding: 20px 40px;
    text-align: left;
}

.FAQ-questions-item-answer-disable {
    display: none;
}

.FAQ-questions-item-answer p{
    margin: 0px;
}

.FAQ-contact-btn {
    text-align: center;
    margin: 40px;
}

@media (max-width: 600px) {
    .pages-header-titles h1 {
        font-size: 15vw;
    }

    .FAQ-container {
        text-align: center;
        width: 90%;
        margin: auto;
    }

    .FAQ-questions-item-title {
        padding: 10px 20px;
    }

    .FAQ-questions-item-title p {
        width: 225px;
    }
}